import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    $("#info-popup").dialog({ autoOpen: false, resizable: false, width: 700 });
  }

  closeModal(event) {
    $(".dialogBox").dialog("close");
  }

  openModal(event) {
    const wineName = event.params.wineName;
    const wineDescription = event.params.wineDescription;
    $("#info-popup .wine-name").text(wineName);
    $("#info-popup .wine-description").text(wineDescription);
    $("#info-popup").dialog("open");
  }
}
